<template>
  <b-card class="invoice-preview-card">
    <b-card-body>
      <b-card-title>
        <b-row>
          <b-col cols="4">
            <h2>Construction</h2>
          </b-col>
          <b-col
            align-self="end"
            class="d-flex align-items-center justify-content-end pr-4"
            cols="2"
          >
            <label
              style="color: #b9b9c3; font-weight: 600; font-size: 12px; line-height: 23px"
              >COGS/SQFT
              <br />
              <h4
                style="
                  color: #636363;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 18px;
                "
              >
                {{
                  Number(constructionTotalCost / quote.sqft).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </h4>
            </label>
          </b-col>
          <b-col
            align-self="start"
            class="d-flex align-items-center justify-content-end"
            cols="2"
          >
            <label
              style="color: #b9b9c3; font-weight: 600; font-size: 12px; line-height: 23px"
              >TOTAL COGS
              <br />
              <h4
                style="
                  color: #636363;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 18px;
                "
              >
                {{
                  Number(constructionTotalCost).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </h4>
            </label>
          </b-col>
          <b-col
            align-self="start"
            class="d-flex align-items-center justify-content-end"
            cols="2"
          >
            <label
              style="color: #b9b9c3; font-weight: 600; font-size: 12px; line-height: 23px"
              >TOTAL PRICE
              <br />
              <h4
                  style="text-align: end !important;"
              >
                {{
                  Number(constructionTotalPrice).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </h4>
            </label>
          </b-col>
          <b-col
            align-self="end"
            class="d-flex align-items-center justify-content-end pr-4"
            cols="2"
          >
            <label
              style="color: #b9b9c3; font-weight: 600; font-size: 12px; line-height: 23px"
              >PRICE/SQFT
              <br />
              <h4
                  style="text-align: end !important;"
              >
                {{
                  Number(Number(constructionTotalPrice) / quote.sqft).toLocaleString(
                    "en-CA",
                    {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    }
                  )
                }}
              </h4>
            </label>
          </b-col>
          <b-col cols="4"> </b-col>
          <b-col
            align-self="end"
            class="d-flex align-items-center justify-content-end pr-4"
            cols="2"
          >
          </b-col>
          <b-col
            align-self="start"
            class="d-flex align-items-center justify-content-end"
            cols="2"
          >
          </b-col>
          <b-col
            align-self="start"
            class="d-flex align-items-center justify-content-end"
            cols="2"
          >
            <label
              style="color: #b9b9c3; font-weight: 600; font-size: 12px; line-height: 23px"
              >TOTAL PROFIT
              <br />
              <h4
                  style="text-align: end !important;"
              >
                {{
                  Number(constructionTotalPrice - constructionTotalCost).toLocaleString(
                    "en-CA",
                    {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    }
                  )
                }}
              </h4>
            </label>
          </b-col>
          <b-col
            align-self="end"
            class="d-flex align-items-center justify-content-end pr-4 text-end"
            cols="2"
          >
            <label
              style="color: #b9b9c3; font-weight: 600; font-size: 12px; line-height: 23px"
              >PROFIT/SQFT
              <br />
              <h4 style="text-align: end !important;">
                {{
                  Number(
                    (constructionTotalPrice - constructionTotalCost) / quote.sqft
                  ).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </h4>
            </label>
          </b-col>
          <b-col cols="12" class="text-end">
            <div v-if="quote.base_spec_version > 3.6">
              <b-row>
                <b-col cols="10"></b-col>
                <b-col
                    align-self="end"
                    class="d-flex align-items-center justify-content-end pr-4 text-end"
                    cols="2">
                  <div>
                    <label
                        class="text-uppercase"
                        style="color: #b9b9c3; font-weight: 600; font-size: 12px; line-height: 23px"
                    >Construction Margin</label>
                    <br>
                    <div class="d-flex align-items-center justify-content-end">{{ contingency_fee }} %
                      <b-button
                          variant="white"
                          class="pr-0 text-primary"
                          :disabled="
                          quote.tfr.tfr_status == 'Quote Completed' ||
                          quote.opportunity.ccdc_signed == true ||
                          quote.tfr.tfr_status == 'Archived'
                        "
                          @click="showContingencyModal = true"
                      >
                        <uil-edit-alt
                            class="logo"
                            style="margin-right: 5px !important"
                            size="15"
                        />
                        Edit
                      </b-button></div>
                    <div class="d-flex align-items-center justify-content-end">
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card-title>
      <!-- <b-row style=" padding-bottom:25px">
            <b-col align-self="end" class="d-flex align-items-center justify-content-end" cols="12">
                <json-excel :data="constructionSpecs" :escape-csv="false" type="xls">
                    <b-button role="button" style="margin-right:10px" variant="outline-secondary">
                        <feather-icon id="tooltip-eye" class="mx-1" icon="DownloadIcon" size="16" style="margin-right: 8px !important;" />
                        <span class="align-middle" style="margin-right:15px">Download Data</span>
                    </b-button>

                </json-excel>
            </b-col>
        </b-row> -->

      <b-row>
        <b-col cols="12" class="pb-4">
          <b-table-simple fixed>
            <b-thead>
              <b-tr>
                <b-th class="table-background-color" colspan="4">
                  Drywall takeoff (W1)
                </b-th>
                <b-th class="table-background-color" colspan="4">
                  Glazing takeoff (g1)
                </b-th>
                <b-th class="table-background-color" colspan="4">
                  Demising Wall takeoff (W2)
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr class="border-group-details">
                <b-td :class="quote.w1 == 0 ? 'muted-number' : ''" colspan="4">
                  {{ quote.w1 }} LF
                </b-td>
                <b-td :class="quote.g1 == 0 ? 'muted-number' : ''" colspan="4">
                  {{ quote.g1 }} LF
                </b-td>
                <b-td :class="quote.w2 == 0 ? 'muted-number' : ''" colspan="4">
                  {{ quote.w2 }} LF
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>

        <b-col
            cols="12"
            class="my-1 pl-0"
            align-self="end"
            style="text-align: end; "
        >
          <div
              class="d-flex align-items-center justify-content-end"
              align-self="end"
              style="text-align: end"
          >
            <b-dropdown
                variant="outline-secondary"
                text="Split Link"
                checkbox-menu
                allow-focus
                dropup
            >
              <template #button-content>
                <span> <uil-columns /> Columns </span>
              </template>
              <b-dropdown-form class="w-100">
                <b-form-group>
                  {{baseSpecSelectedAll}}
                  <template #label>
                    <b-form-checkbox
                        v-model="allSelected"
                        aria-describedby="flavours"
                        aria-controls="flavours"
                        @change="toggleAllDrop"
                    >
                      {{ allSelected ? "Deselect All" : "Select All" }}
                    </b-form-checkbox>
                    <b-dropdown-divider />
                  </template>
                  <b-form-checkbox
                      v-model="displayColumnsCheck"
                      v-for="(f, index) in quote.base_spec_version>4.2 ?fields:fields.filter(f=>f.key!=='rebate')"
                      :key="'check' + index"
                      :value="f.key"
                  >{{ f.label }}
                  </b-form-checkbox>
                </b-form-group>
                <b-dropdown-divider />
                <div style="display: flex; align-items: end; justify-content: end">
                  <b-button
                      class="py-0"
                      variant="outline-light"
                      style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                      @click="resetCols()"
                  >Reset</b-button
                  >
                </div>
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </b-col>
        <template
            v-for="(cat,index) in quote.construction_hierarchy"
        >
          <b-col cols="12" class="pb-4" :key="cat.level_2_name+'-'+index" style="overflow-x: auto;">
            <h4>{{ cat.level_2_name }}</h4>
            <b-table-simple>
              <b-thead>
                <b-tr>
                  <b-th class="table-background-color" colspan="4" style="width: 15% !important;"> ITEM </b-th>
                  <b-th v-if="showColumnsFiltered('cogs_unit')" class="table-background-color text-right" colspan="2"> COGS / Unit </b-th>
                  <b-th v-if="showColumnsFiltered('price_unit')" class="table-background-color text-right" colspan="2"> PRICE / Unit</b-th>
                  <b-th v-if="showColumnsFiltered('quantity')" class="table-background-color text-right" colspan="2"> QUANTITY </b-th>
                  <b-th v-if="showColumnsFiltered('total_cost')" class="table-background-color text-right" colspan="2"> Total COGS </b-th>
                  <b-th v-if="showColumnsFiltered('total_price')" class="table-background-color text-right" colspan="2"> Total PRICE</b-th>
                  <b-th v-if="showColumnsFiltered('cost_per_sqft')" class="table-background-color text-right" colspan="2"> COGS / SQFT </b-th>
                  <b-th v-if="showColumnsFiltered('rebate') && quote.base_spec_version>4.2" class="table-background-color text-right" colspan="2"><div style="min-width: 80px">
                    Other profit
                  </div></b-th>
                  <b-th
                      v-if="Number(quote.base_spec_version) >= 3.5 && showColumnsFiltered('actions')"
                      class="table-background-color"
                      colspan="2"
                  >
                    Actions
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-for="construction_spec in cat.items">
                  <b-tr class="border-group-details" :key="construction_spec.id">
                    <b-td
                        colspan="4"
                        style="font-weight: 600; font-size: 14px; line-height: 21px"
                    >
                      <div
                          v-if="
                          constructionAdjustments(construction_spec.construction_work_id)
                            .length != 0
                        "
                          class="d-flex"
                      >
                        <uil-arrow-growth
                            style="color: #ff9f43; margin-top: 5px"
                            class="mr-1"
                        />
                        <div
                            v-for="(
                            construction_adjustment, index
                          ) in constructionAdjustments(
                            construction_spec.construction_work_id
                          )"
                            :key="construction_adjustment + 'row_adjusted_name' + index"
                        >
                          <div v-if="construction_adjustment.name != ''">
                            {{ construction_adjustment.name }}
                          </div>
                          <div v-else>{{ construction_spec.name }}</div>
                        </div>
                      </div>
                      <div v-else>
                        {{ construction_spec.name }}
                      </div>
                    </b-td>
                    <b-td colspan="2" class="text-right"
                          v-if="showColumnsFiltered('cogs_unit')">
                      {{
                        Number(
                            construction_spec.unit_cost +
                            constructionAdjustmentTotalUnitCost(
                                construction_spec.construction_work_id
                            )
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </b-td>
                    <b-td colspan="2"  v-if="showColumnsFiltered('price_unit')" class="text-right">
                      {{
                        Number(
                            Number(construction_spec.unit_price) +
                            constructionAdjustmentTotalUnitPrice(
                                construction_spec.construction_work_id
                            )
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </b-td>
                    <b-td colspan="2" v-if="showColumnsFiltered('quantity')" class="text-right">
                      {{
                        Intl.NumberFormat('en-US').format(Number(
                            construction_spec.qty +
                            constructionAdjustmentTotalQuantity(
                                construction_spec.construction_work_id
                            )
                        ))
                         +
                        " " +
                        construction_spec.unit_type
                      }}
                    </b-td>
                    <b-td colspan="2" v-if="showColumnsFiltered('total_cost')" class="text-right">
                      {{
                        Number(
                            construction_spec.total_cost +
                            constructionAdjustmentTotalCost(
                                construction_spec.construction_work_id
                            )
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </b-td>
                    <b-td colspan="2" v-if="showColumnsFiltered('total_price')" class="text-right">
                      <div
                          v-if="
                          Number(
                            construction_spec.qty +
                              constructionAdjustmentTotalQuantity(
                                construction_spec.construction_work_id
                              )
                          ) == 0
                        "
                      >
                        $0.00
                      </div>
                      <div v-else>
                        {{
                          Number(
                              constructionAdjustmentTotalPrice(
                                  construction_spec.construction_work_id
                              ) +
                              Number(construction_spec.total_price) +
                              (Number(
                                  construction_spec.qty +
                                  constructionAdjustmentTotalQuantity(
                                      construction_spec.construction_work_id
                                  )
                              ) == 0
                                  ? 0
                                  : contingencyFeeCalc(construction_spec))
                          ).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </div>
                    </b-td>
                    <b-td colspan="2" v-if="showColumnsFiltered('cost_per_sqft')" class="text-right">
                      {{
                        Number(
                            construction_spec.cost_per_sqft +
                            constructionAdjustmentTotalCostPerSqft(
                                construction_spec.construction_work_id
                            )
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </b-td>
                    <b-td colspan="2" v-if="showColumnsFiltered('rebate')&& quote.base_spec_version>4.2" class="text-right">
                      <div>
                        {{
                          Number((( (construction_spec.rebate_total*1+construction_spec.other_profit_total*1)+constructionAdjustmentRebateUnit(
                              construction_spec.construction_work_id
                          )))).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </div>
                    </b-td>
                    <b-td colspan="2" v-if="Number(quote.base_spec_version) >= 3.5 && showColumnsFiltered('actions')">
                      <b-button
                          v-if="(construction_spec.name !== 'Site Supervision' && construction_spec.name !== 'Parking') ||
                        quote.base_spec_version < 4.2"
                          @click="updateConstructionItem(construction_spec)"
                          variant="white"
                          class="d-flex"
                          :disabled="
                          quote.tfr.tfr_status == 'Quote Completed' ||
                          quote.opportunity.ccdc_signed == true ||
                          quote.tfr.tfr_status == 'Archived'
                        "
                      >
                        <uil-edit-alt
                            class="logo"
                            style="margin-right: 5px !important"
                            size="15"
                        />
                        Edit
                      </b-button>
                      <div class="text-center d-flex"></div>
                    </b-td>
                  </b-tr>

                  <template
                      v-if="
                      constructionAdjustments(construction_spec.construction_work_id)
                        .length != 0
                    "
                  >
                    <b-tr
                        style="background: #f8f8f8"
                        class="border-group-details"
                        :key="'base-spec' + construction_spec.id"
                    >
                      <b-td
                          colspan="4"
                          style="font-weight: 400; font-size: 12px; line-height: 18px"
                      >
                        Base Spec
                      </b-td>
                      <b-td colspan="2" class="muted-base-spec text-right" v-if="showColumnsFiltered('cogs_unit')">
                        {{
                          Number(construction_spec.unit_cost).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </b-td>
                      <b-td colspan="2" class="muted-base-spec text-right" v-if="showColumnsFiltered('price_unit')">
                        {{
                          Number(construction_spec.unit_price).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </b-td>
                      <b-td colspan="2" class="muted-base-spec text-right" v-if="showColumnsFiltered('quantity')">
                        {{ construction_spec.qty + " " + construction_spec.unit_type }}
                      </b-td>
                      <b-td colspan="2" class="muted-base-spec text-right" v-if="showColumnsFiltered('total_cost')">
                        {{
                          Number(construction_spec.total_cost).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </b-td>

                      <b-td colspan="2" class="muted-base-spec text-right" v-if="showColumnsFiltered('total_price')">
                        {{
                          Intl.NumberFormat('en-US').format
                          (Number(
                              Number(construction_spec.unit_price) *
                              Number(construction_spec.qty) +
                              contingencyFeeCalc(construction_spec)
                          ))
                        }}
                      </b-td>
                      <b-td colspan="2" class="muted-base-spec text-right" v-if="showColumnsFiltered('cost_per_sqft')">
                        {{
                          Number(construction_spec.cost_per_sqft).toLocaleString(
                              "en-CA",
                              {
                                minimumFractionDigits: 2,
                                style: "currency",
                                currency: "CAD",
                              }
                          )
                        }}
                      </b-td>
                      <b-td class="muted-base-spec text-right" v-if="showColumnsFiltered('rebate')&& quote.base_spec_version>4.2" colspan="2">
                        {{Number(construction_spec.other_profit_total*1).toLocaleString(
                          "en-CA",
                          {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          }
                      )}}
                      </b-td>
                      <b-td colspan="2"
                            style="font-weight: 400; font-size: 12px; line-height: 18px"
                          v-if="Number(quote.base_spec_version) >= 3.5 && showColumnsFiltered('actions')"
                      >
                      </b-td>
                    </b-tr>
                    <b-tr
                        style="background: #f8f8f8"
                        class="border-group-details"
                        :key="
                        'adjustment' +
                        construction_spec.construction_work_id +
                        construction_adjustment.id
                      "
                        v-for="construction_adjustment in constructionAdjustments(
                        construction_spec.construction_work_id
                      )"
                    >
                      <b-td
                          colspan="4"
                          style="font-weight: 400; font-size: 12px; line-height: 18px"
                      >
                        <div v-if="construction_adjustment.is_spec_swap">
                          <div style="font-weight: 700">
                            {{ construction_adjustment.name }}
                          </div>
                          <div>
                            {{ construction_adjustment.details }}
                          </div>
                        </div>
                        <div class="text-muted">
                          {{ construction_adjustment.internal_note }}
                        </div>
                      </b-td>
                      <b-td
                          colspan="2"
                          style="font-weight: 400; font-size: 12px; line-height: 18px"
                          v-if="showColumnsFiltered('cogs_unit')"
                          class="text-right"
                      >
                        <span
                            style="white-space: nowrap"
                            :class="
                            construction_adjustment.unit_cost > 0
                              ? 'increase'
                              : 'decrease'
                          "
                            v-if="construction_adjustment.unit_cost != 0"
                        >
                          <uil-arrow-up v-if="construction_adjustment.unit_cost > 0" />
                          <uil-arrow-down
                              v-else-if="construction_adjustment.unit_cost < 0"
                          />{{
                            Number(construction_adjustment.unit_cost).toLocaleString(
                                "en-CA",
                                {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "CAD",
                                }
                            )
                          }}
                        </span>
                      </b-td>
                      <b-td
                          colspan="2"
                          style="font-weight: 400; font-size: 12px; line-height: 18px"
                          class="text-right"
                          v-if="showColumnsFiltered('price_unit')"
                      >
                        <span
                            style="white-space: nowrap"
                            :class="
                            construction_adjustment.unit_price > 0
                              ? 'increase'
                              : 'decrease'
                          "
                            v-if="construction_adjustment.unit_price != 0"
                        >
                          <uil-arrow-up v-if="construction_adjustment.unit_price > 0" />
                          <uil-arrow-down
                              v-else-if="construction_adjustment.unit_price < 0"
                          />{{
                            Number(construction_adjustment.unit_price).toLocaleString(
                                "en-CA",
                                {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "CAD",
                                }
                            )
                          }}
                        </span>
                      </b-td>
                      <b-td
                          colspan="2"
                          style="font-weight: 400; font-size: 12px; line-height: 18px"
                          v-if="showColumnsFiltered('quantity')"
                          class="text-right"
                      >
                        <span
                            style="white-space: nowrap"
                            :class="
                            construction_adjustment.quantity > 0 ? 'increase' : 'decrease'
                          "
                            v-if="construction_adjustment.quantity != 0"
                        >
                          <uil-arrow-up v-if="construction_adjustment.quantity > 0" />
                          <uil-arrow-down
                              v-else-if="construction_adjustment.quantity < 0"
                          />{{
                            Intl.NumberFormat('en-US').format
                            (Number(construction_adjustment.quantity))
                          }}
                        </span>
                      </b-td>

                      <b-td
                          colspan="2"
                          style="font-weight: 400; font-size: 12px; line-height: 18px"
                          v-if="showColumnsFiltered('total_cost')"
                          class="text-right"
                      >
                        <span
                            style="white-space: nowrap"
                            :class="
                            construction_adjustment.total_cost > 0
                              ? 'increase'
                              : 'decrease'
                          "
                            v-if="construction_adjustment.total_cost != 0"
                        >
                          <uil-arrow-up v-if="construction_adjustment.total_cost > 0" />
                          <uil-arrow-down
                              v-else-if="construction_adjustment.total_cost < 0"
                          />{{
                            Number(construction_adjustment.total_cost).toLocaleString(
                                "en-CA",
                                {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "CAD",
                                }
                            )
                          }}
                        </span>
                      </b-td>
                      <b-td
                          colspan="2"
                          style="font-weight: 400; font-size: 12px; line-height: 18px"
                          v-if="showColumnsFiltered('total_price')"
                          class="text-right"
                      >
                        <span
                            style="white-space: nowrap"
                            :class="
                            Number(construction_adjustment.total_price+contingencyFeeCalc(construction_adjustment)) > 0
                              ? 'increase'
                              : 'decrease'
                          "
                            v-if="construction_adjustment.total_price+contingencyFeeCalc(construction_adjustment) !== 0"
                        >
                          <uil-arrow-up
                              v-if="Number(construction_adjustment.total_price) + contingencyFeeCalc(construction_adjustment) > 0"
                          />
                          <uil-arrow-down
                              v-else-if="Number(construction_adjustment.total_price) + contingencyFeeCalc(construction_adjustment) < 0"
                          />
                          {{
                            Number(
                                Number(construction_adjustment.total_price) + contingencyFeeCalc(construction_adjustment)
                            ).toLocaleString("en-CA", {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            })
                          }}
                          {{ construction_adjustment.qty }}
                        </span>
                      </b-td>
                      <b-td
                          colspan="2"
                          style="font-weight: 400; font-size: 12px; line-height: 18px"
                          v-if="showColumnsFiltered('cost_per_sqft')"
                          class="text-right"
                      >
                        <span
                            style="white-space: nowrap"
                            :class="
                            construction_adjustment.cost_per_sqft > 0
                              ? 'increase'
                              : 'decrease'
                          "
                            v-if="construction_adjustment.cost_per_sqft != 0"
                        >
                          <uil-arrow-up
                              v-if="construction_adjustment.cost_per_sqft > 0"
                          />
                          <uil-arrow-down
                              v-else-if="construction_adjustment.cost_per_sqft < 0"
                          />{{
                            Number(construction_adjustment.cost_per_sqft).toLocaleString(
                                "en-CA",
                                {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "CAD",
                                }
                            )
                          }}
                        </span>
                      </b-td>
                      <b-td v-if="showColumnsFiltered('rebate')&& quote.base_spec_version>4.2" class="text-right" colspan="2">
                        <span
                            style="white-space: nowrap"
                            :class="
                            construction_adjustment.rebate_total > 0
                              ? 'increase'
                              : 'decrease'
                          "
                            v-if="construction_adjustment.rebate_total != 0 "
                        >
                          <uil-arrow-up
                              v-if="construction_adjustment.rebate_total > 0"
                          />
                          <uil-arrow-down
                              v-else-if="construction_adjustment.rebate_total < 0"
                          />{{
                            Number(construction_adjustment.rebate_total).toLocaleString(
                                "en-CA",
                                {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "CAD",
                                }
                            )
                          }}
                        </span>
                      </b-td>
                      <b-td
                          colspan="2"
                          style="font-weight: 400; font-size: 12px; line-height: 18px"
                          v-if="Number(quote.base_spec_version) >= 3.5 && showColumnsFiltered('actions')"
                      >
                      </b-td>
                    </b-tr>
                  </template>
                </template>
                <b-tr
                    class="bg-light"
                    style="
                    border-top-style: solid;
                    border-width: 2px;
                    border-color: #d8d6de;
                  "
                >
                  <b-td colspan="4" style="font-weight: 600">TOTALS</b-td>
                  <b-td colspan="2" v-if="showColumnsFiltered('cogs_unit')"></b-td>
                  <b-td colspan="2" v-if="showColumnsFiltered('price_unit')"></b-td>
                  <b-td colspan="2" v-if="showColumnsFiltered('quantity')"></b-td>
                  <b-td colspan="2"
                        v-if="showColumnsFiltered('total_cost')"
                        class="text-right"
                  ><b>
                    {{
                      Number(hierarchy_grouping_total_cogs(cat.level_2_name)).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}</b
                  ></b-td
                  >
                  <b-td colspan="2" v-if="showColumnsFiltered('total_price')" class="text-right">
                    <b>
                      {{
                        Number(hierarchy_grouping_total_price(cat.level_2_name)).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}</b
                    >
                  </b-td>
                  <b-td colspan="2" v-if="showColumnsFiltered('cost_per_sqft')"
                        class="text-right"
                  ><b>
                    {{
                      Number((hierarchy_grouping_total_cogs(cat.level_2_name)*1)/quote.sqft).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}</b
                  ></b-td
                  >
                  <b-td colspan="2" v-if="showColumnsFiltered('rebate')&& quote.base_spec_version>4.2" />
                  <b-td v-if="showColumnsFiltered('actions')" colspan="2" />
                </b-tr>
                <b-tr
                    class="border-group-details"
                    v-if="cmFeeCalc > 0 && cat.level_2_name == 'A-13 - General Requirements' && quote.base_spec_version<4.2"
                >
                  <td style="font-weight: 600; font-size: 14px" colspan="4">
                    <p>CM Fee</p>
                  </td>
                  <td colspan="2" v-if="showColumnsFiltered('cogs_unit')">
                    <div class="mx-1"></div>
                  </td>
                  <td colspan="2" v-if="showColumnsFiltered('price_unit')"></td>
                  <td colspan="2" v-if="showColumnsFiltered('quantity')"></td>
                  <td colspan="2" v-if="showColumnsFiltered('total_cost')"></td>
                  <td colspan="2" v-if="showColumnsFiltered('total_price')">
                    {{
                      Number(cmFeeCalc).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </td>
                  <td colspan="2" v-if="showColumnsFiltered('cost_per_sqft')">
                    {{
                      Number(Number(cmFeeCalc) / quote.sqft).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </template>
<!--
 <template
            v-if="quote.base_spec_version<=4.2"
          v-for="name in quote.base_spec_version < 3.5 ? sortingArr : sortingArr_v2"
        >
          <b-col cols="12" class="pb-4" :key="name">
            <h4>{{ name }}</h4>
            <b-table-simple fixed>
              <b-thead>
                <b-tr>
                  <b-th class="table-background-color" colspan="4"> ITEM </b-th>
                  <b-th v-if="showColumnsFiltered('cogs_unit')"  class="table-background-color" colspan="2"> COGS / Unit </b-th>
                  <b-th v-if="showColumnsFiltered('price_unit')" class="table-background-color" colspan="2"> PRICE / Unit</b-th>
                  <b-th v-if="showColumnsFiltered('quantity')" class="table-background-color" colspan="2"> QUANTITY </b-th>
                  <b-th v-if="showColumnsFiltered('total_cost')" class="table-background-color" colspan="2"> Total COGS </b-th>
                  <b-th v-if="showColumnsFiltered('total_price')" class="table-background-color" colspan="2"> Total PRICE</b-th>
                  <b-th v-if="showColumnsFiltered('cost_per_sqft')" class="table-background-color" colspan="2"> COGS / SQFT </b-th>
                  <b-th
                    v-if="Number(quote.base_spec_version) >= 3.5 && showColumnsFiltered('actions')"
                    class="table-background-color"
                    colspan="2"
                  >
                    Actions
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-for="construction_spec in constructtionSpecFiltered(name)">
                  <b-tr class="border-group-details" :key="construction_spec.id">
                    <b-td
                      colspan="4"
                      style="font-weight: 600; font-size: 14px; line-height: 21px"
                    >
                      <div
                        v-if="
                          constructionAdjustments(construction_spec.construction_work_id)
                            .length != 0
                        "
                        class="d-flex"
                      >
                        <uil-arrow-growth
                          style="color: #ff9f43; margin-top: 5px"
                          class="mr-1"
                        />
                        <div
                          v-for="(
                            construction_adjustment, index
                          ) in constructionAdjustments(
                            construction_spec.construction_work_id
                          )"
                          :key="construction_adjustment + 'row_adjusted_name' + index"
                        >
                          <div v-if="construction_adjustment.name != ''">
                            {{ construction_adjustment.name }}
                          </div>
                          <div v-else>{{ construction_spec.name }}</div>
                        </div>
                      </div>
                      <div v-else>
                        {{ construction_spec.name }}
                      </div>
                    </b-td>
                    <b-td colspan="2" v-if="showColumnsFiltered('cogs_unit')" >
                      {{
                        Number(
                          construction_spec.unit_cost +
                            constructionAdjustmentTotalUnitCost(
                              construction_spec.construction_work_id
                            )
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </b-td>
                    <b-td colspan="2" v-if="showColumnsFiltered('price_unit')" >
                      {{
                        Number(
                          Number(construction_spec.unit_price) +
                            constructionAdjustmentTotalUnitPrice(
                              construction_spec.construction_work_id
                            )
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </b-td>
                    <b-td colspan="2" v-if="showColumnsFiltered('quantity')" >
                      {{
                        Number(
                          construction_spec.qty +
                            constructionAdjustmentTotalQuantity(
                              construction_spec.construction_work_id
                            )
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                        }) +
                        " " +
                        construction_spec.unit_type
                      }}
                    </b-td>
                    <b-td colspan="2" v-if="showColumnsFiltered('total_cost')" >
                      {{
                        Number(
                          construction_spec.total_cost +
                            constructionAdjustmentTotalCost(
                              construction_spec.construction_work_id
                            )
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </b-td>
                    <b-td colspan="2" v-if="showColumnsFiltered('total_price')" >
                      <div
                        v-if="
                          Number(
                            construction_spec.qty +
                              constructionAdjustmentTotalQuantity(
                                construction_spec.construction_work_id
                              )
                          ) == 0
                        "
                      >
                        $0.00
                      </div>
                      <div v-else>
                        {{
                          Number(
                            constructionAdjustmentTotalPrice(
                              construction_spec.construction_work_id
                            ) +
                              Number(construction_spec.total_price) +
                              (Number(
                                construction_spec.qty +
                                  constructionAdjustmentTotalQuantity(
                                    construction_spec.construction_work_id
                                  )
                              ) == 0
                                ? 0
                                : contingencyFeeCalc(construction_spec))
                          ).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </div>
                    </b-td>
                    <b-td colspan="2" v-if="showColumnsFiltered('cost_per_sqft')" >
                      {{
                        Number(
                          construction_spec.cost_per_sqft +
                            constructionAdjustmentTotalCostPerSqft(
                              construction_spec.construction_work_id
                            )
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </b-td>
                    <b-td v-if="Number(quote.base_spec_version) >= 3.5 && showColumnsFiltered('actions')">
                      <b-button
                          v-if="(construction_spec.name !== 'Site Supervision' && construction_spec.name !== 'Parking') ||
                        quote.base_spec_version < 4.2"
                        @click="updateConstructionItem(construction_spec)"
                        variant="white"
                        class="d-flex"
                        :disabled="
                          quote.tfr.tfr_status == 'Quote Completed' ||
                          quote.opportunity.ccdc_signed == true ||
                          quote.tfr.tfr_status == 'Archived'
                        "
                      >
                        <uil-edit-alt
                          class="logo"
                          style="margin-right: 5px !important"
                          size="15"
                        />
                        Edit
                      </b-button>
                      <div class="text-center d-flex"></div>
                    </b-td>
                  </b-tr>

                  <template
                    v-if="
                      constructionAdjustments(construction_spec.construction_work_id)
                        .length != 0
                    "
                  >
                    <b-tr
                      style="background: #f8f8f8"
                      class="border-group-details"
                      :key="'base-spec' + construction_spec.id"
                    >
                      <b-td
                        colspan="4"
                        style="font-weight: 400; font-size: 12px; line-height: 18px"
                      >
                        Base Spec
                      </b-td>
                      <b-td colspan="2" class="muted-base-spec" v-if="showColumnsFiltered('cogs_unit')" >
                        {{
                          Number(construction_spec.unit_cost).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </b-td>
                      <b-td colspan="2" class="muted-base-spec" v-if="showColumnsFiltered('price_unit')" >
                        {{
                          Number(construction_spec.unit_price).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </b-td>
                      <b-td colspan="2" class="muted-base-spec" v-if="showColumnsFiltered('quantity')" >
                        {{ construction_spec.qty + " " + construction_spec.unit_type }}
                      </b-td>
                      <b-td colspan="2" class="muted-base-spec" v-if="showColumnsFiltered('total_cost')" >
                        {{
                          Number(construction_spec.total_cost).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </b-td>

                      <b-td colspan="2" class="muted-base-spec" v-if="showColumnsFiltered('total_price')" >
                        {{
                          Number(
                            Number(construction_spec.unit_price) *
                              Number(construction_spec.qty) +
                              contingencyFeeCalc(construction_spec)
                          ).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </b-td>
                      <b-td colspan="2" class="muted-base-spec" v-if="showColumnsFiltered('cost_per_sqft')" >
                        {{
                          Number(construction_spec.cost_per_sqft).toLocaleString(
                            "en-CA",
                            {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            }
                          )
                        }}
                      </b-td>

                      <b-td
                        colspan="2"
                        style="font-weight: 400; font-size: 12px; line-height: 18px"
                        v-if="Number(quote.base_spec_version) >= 3.5 && showColumnsFiltered('actions')"
                      >
                      </b-td>
                    </b-tr>
                    <b-tr
                      style="background: #f8f8f8"
                      class="border-group-details"
                      :key="
                        'adjustment' +
                        construction_spec.construction_work_id +
                        construction_adjustment.id
                      "
                      v-for="construction_adjustment in constructionAdjustments(
                        construction_spec.construction_work_id
                      )"
                    >
                      <b-td
                        colspan="4"
                        style="font-weight: 400; font-size: 12px; line-height: 18px"
                      >
                        <div v-if="construction_adjustment.is_spec_swap">
                          <div style="font-weight: 700">
                            {{ construction_adjustment.name }}
                          </div>
                          <div>
                            {{ construction_adjustment.details }}
                          </div>
                        </div>
                        <div class="text-muted">
                          {{ construction_adjustment.internal_note }}
                        </div>
                      </b-td>
                      <b-td
                        colspan="2"
                        style="font-weight: 400; font-size: 12px; line-height: 18px"
                        v-if="showColumnsFiltered('cogs_unit')"
                      >
                        <span
                          style="white-space: nowrap"
                          :class="
                            construction_adjustment.unit_cost > 0
                              ? 'increase'
                              : 'decrease'
                          "
                          v-if="construction_adjustment.unit_cost != 0"
                        >
                          <uil-arrow-up v-if="construction_adjustment.unit_cost > 0" />
                          <uil-arrow-down
                            v-else-if="construction_adjustment.unit_cost < 0"
                          />{{
                            Number(construction_adjustment.unit_cost).toLocaleString(
                              "en-CA",
                              {
                                minimumFractionDigits: 2,
                                style: "currency",
                                currency: "CAD",
                              }
                            )
                          }}
                        </span>
                      </b-td>
                      <b-td
                        colspan="2"
                        style="font-weight: 400; font-size: 12px; line-height: 18px"
                        v-if="showColumnsFiltered('price_unit')"
                      >
                        <span
                          style="white-space: nowrap"
                          :class="
                            construction_adjustment.unit_price > 0
                              ? 'increase'
                              : 'decrease'
                          "
                          v-if="construction_adjustment.unit_price != 0"
                        >
                          <uil-arrow-up v-if="construction_adjustment.unit_price > 0" />
                          <uil-arrow-down
                            v-else-if="construction_adjustment.unit_price < 0"
                          />{{
                            Number(construction_adjustment.unit_price).toLocaleString(
                              "en-CA",
                              {
                                minimumFractionDigits: 2,
                                style: "currency",
                                currency: "CAD",
                              }
                            )
                          }}
                        </span>
                      </b-td>
                      <b-td
                        colspan="2"
                        style="font-weight: 400; font-size: 12px; line-height: 18px"
                        v-if="showColumnsFiltered('quantity')"
                      >
                        <span
                          style="white-space: nowrap"
                          :class="
                            construction_adjustment.quantity > 0 ? 'increase' : 'decrease'
                          "
                          v-if="construction_adjustment.quantity != 0"
                        >
                          <uil-arrow-up v-if="construction_adjustment.quantity > 0" />
                          <uil-arrow-down
                            v-else-if="construction_adjustment.quantity < 0"
                          />{{
                            Number(construction_adjustment.quantity).toLocaleString(
                              "en-CA",
                              {
                                minimumFractionDigits: 2,
                              }
                            )
                          }}
                        </span>
                      </b-td>

                      <b-td
                        colspan="2"
                        style="font-weight: 400; font-size: 12px; line-height: 18px"
                        v-if="showColumnsFiltered('total_cost')"
                      >
                        <span
                          style="white-space: nowrap"
                          :class="
                            construction_adjustment.total_cost > 0
                              ? 'increase'
                              : 'decrease'
                          "
                          v-if="construction_adjustment.total_cost != 0"
                        >
                          <uil-arrow-up v-if="construction_adjustment.total_cost > 0" />
                          <uil-arrow-down
                            v-else-if="construction_adjustment.total_cost < 0"
                          />{{
                            Number(construction_adjustment.total_cost).toLocaleString(
                              "en-CA",
                              {
                                minimumFractionDigits: 2,
                                style: "currency",
                                currency: "CAD",
                              }
                            )
                          }}
                        </span>
                      </b-td>
                      <b-td
                        colspan="2"
                        style="font-weight: 400; font-size: 12px; line-height: 18px"
                        v-if="showColumnsFiltered('total_price')"
                      >
                        <span
                          style="white-space: nowrap"
                          :class="
                            Number(construction_adjustment.total_price+contingencyFeeCalc(construction_adjustment)) > 0
                              ? 'increase'
                              : 'decrease'
                          "
                          v-if="construction_adjustment.total_price+contingencyFeeCalc(construction_adjustment) !== 0"
                        >
                          <uil-arrow-up
                            v-if="Number(construction_adjustment.total_price) + contingencyFeeCalc(construction_adjustment) > 0"
                          />
                          <uil-arrow-down
                            v-else-if="Number(construction_adjustment.total_price) + contingencyFeeCalc(construction_adjustment) < 0"
                          />
                          {{
                            Number(
                              Number(construction_adjustment.total_price) + contingencyFeeCalc(construction_adjustment)
                            ).toLocaleString("en-CA", {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            })
                          }}
                          {{ construction_adjustment.qty }}
                        </span>
                      </b-td>
                      <b-td
                        colspan="2"
                        style="font-weight: 400; font-size: 12px; line-height: 18px"
                        v-if="showColumnsFiltered('cost_per_sqft')"
                      >
                        <span
                          style="white-space: nowrap"
                          :class="
                            construction_adjustment.cost_per_sqft > 0
                              ? 'increase'
                              : 'decrease'
                          "
                          v-if="construction_adjustment.cost_per_sqft != 0"
                        >
                          <uil-arrow-up
                            v-if="construction_adjustment.cost_per_sqft > 0"
                          />
                          <uil-arrow-down
                            v-else-if="construction_adjustment.cost_per_sqft < 0"
                          />{{
                            Number(construction_adjustment.cost_per_sqft).toLocaleString(
                              "en-CA",
                              {
                                minimumFractionDigits: 2,
                                style: "currency",
                                currency: "CAD",
                              }
                            )
                          }}
                        </span>
                      </b-td>
                      <b-td
                        colspan="2"
                        style="font-weight: 400; font-size: 12px; line-height: 18px"
                        v-if="Number(quote.base_spec_version) >= 3.5 && showColumnsFiltered('actions')"
                      >
                      </b-td>
                    </b-tr>
                  </template>
                </template>
                <b-tr
                  style="
                    border-top-style: solid;
                    border-width: 2px;
                    border-color: #d8d6de;
                  "
                  v-if="
                    name == 'Electrical (by Space)' ||
                    name == 'Electrical (Takeoff)' ||
                    name == 'Electrical'
                  "
                >
                  <b-td colspan="4" style="font-weight: 600">TOTALS</b-td>
                  <b-td v-if="showColumnsFiltered('cogs_unit')" />
                  <b-td v-if="showColumnsFiltered('price_unit')" />
                  <b-td v-if="showColumnsFiltered('quantity')" />

                  <b-td colspan="2"
                    ><b>
                      {{
                        Number(totalCost(name)).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}</b
                    ></b-td
                  >
                  <b-td colspan="2">
                    <b>
                      {{
                        Number(totalPrice(name)).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}</b
                    >
                  </b-td>
                  <b-td colspan="2"
                    ><b>
                      {{
                        Number(totalCostSqft(name)).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}</b
                    ></b-td
                  >
                </b-tr>
                <b-tr
                  class="border-group-details"
                  v-if="cmFeeCalc > 0 && name == 'General Requirements' && quote.base_spec_version<4.2"
                >
                  <td style="font-weight: 600; font-size: 14px" colspan="4">
                    <p>CM Fee</p>
                  </td>
                  <td colspan="2" v-if="showColumnsFiltered('cogs_unit')" >
                    <div class="mx-1"></div>
                  </td>
                  <td colspan="2" v-if="showColumnsFiltered('price_unit')" ></td>
                  <td colspan="2" v-if="showColumnsFiltered('quantity')" ></td>
                  <td colspan="2" v-if="showColumnsFiltered('total_cost')" ></td>
                  <td colspan="2" v-if="showColumnsFiltered('total_price')" >
                    {{
                      Number(cmFeeCalc).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </td>
                  <td colspan="2" v-if="showColumnsFiltered('cost_per_sqft')" >
                    {{
                      Number(Number(cmFeeCalc) / quote.sqft).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </template>
-->

        <b-col cols="12" class="pb-4" v-if="constructionManagementFee.length > 0">
          <h4>Construction Management Fee</h4>
          <b-table-simple fixed>
            <b-thead>
              <b-tr>
                <b-th class="table-background-color" colspan="4"> ITEM </b-th>
                <b-th class="table-background-color" colspan="2"> COGS / Unit </b-th>
                <b-th class="table-background-color" colspan="2"> QUANTITY </b-th>
                <b-th class="table-background-color" colspan="2"> Total COGS </b-th>
                <b-th class="table-background-color" colspan="2"> COGS / SQFT </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                class="border-group-details"
                v-for="construction_spec in constructionManagementFee"
                :key="construction_spec.id"
              >
                <b-td
                  colspan="4"
                  style="font-weight: 600; font-size: 14px; line-height: 21px"
                >
                  {{ construction_spec.name }}
                </b-td>
                <b-td colspan="2">
                  {{
                    Number(construction_spec.unit_cost * 100).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    }) + "%"
                  }}
                </b-td>
                <b-td colspan="2"> - </b-td>
                <b-td colspan="2">
                  {{
                    Number(construction_spec.total_cost).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
                <b-td colspan="2">
                  {{
                    Number(construction_spec.cost_per_sqft).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card-body>

    <construction-swap
      :base_spec_version="quote.base_spec_version"
      :quoteId="quote.id"
      :sqft="quote.sqft"
      :constructionItems="quoteConstructionItems"
      :selectedItem="selectedItem"
      :cstrn_adjustment_item_filter="cstrn_adjustment_item"
      :cm_fee_percentage="quote.cm_fee_percentage"
      :cm_fee_enabled="quote.cm_fee_enabled"
      :contingency_fee_percentage="quote.contingency_fee_percentage"
      @updatedOptions="childOptions"
      @swapSpec="cstrnSwapSpec"
      @deleteItem="deleteCstrnItem"
    />

    <!-- start:: contigency modal -->
    <b-modal
      title="Update Contingency Margin"
      v-model="showContingencyModal"
      cancel-title="Cancel"
      cancel-variant="outline-secondary"
      :centered="true"
      body-class="modal-padding"
      @ok="updateContingencyFee"
      ok-title="Update"
      @cancel="resetContingencyFee"
      @hide="resetContingencyFee"
      :ok-disabled="contingency_fee == ''"
    >
      <validation-observer ref="updateContingencyFee" tag="form">
        <form ref="updateContingencyFee" @submit.stop.prevent="updateContingencyFee">
          <validation-provider
            #default="{ errors }"
            name="Construction Contingency Fee"
            rules="required"
          >
            <b-form-group label="Construction Contingency Fee" label-for="name-input">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="PercentIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="Construction Contingency Fee"
                  v-model="contingency_fee"
                  required
                  step="0.01"
                  type="number"
                  placeholder="5"
                ></b-form-input>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </form>
      </validation-observer>
    </b-modal>
    <!-- end:: contingency modal -->
  </b-card>
</template>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<script>
import {
  BCard,
  BCardBody,
  BCol,
  BRow,
  BTable,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BPagination,
  BButton,
  BCardTitle,
  BSpinner,
  BTableSimple,
  BTr,
  BTh,
  BThead,
  BTbody,
  BTd,
  VBModal,
  BFormInput,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup, BDropdownDivider, BDropdownForm,
} from "bootstrap-vue";
import ConstructionSwap from "./Modals/ConstructionSwap.vue";
import vSelect from "vue-select";
import JsonExcel from "vue-json-excel";
import {
  UilArrowGrowth,
  UilArrowUp,
  UilArrowDown,
  UilEditAlt,
  UilColumns,
} from "@iconscout/vue-unicons";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "ReviewConstructionSpecSection",
  data() {
    return {
      breakdownFields: [
        {
          key: "name",
          label: "ITEM",
          sortable: true,
        },
        {
          key: "qty",
          label: "QTY",
          sortable: false,
        },
        {
          key: "total_cost",
          label: "Cost",
          sortable: true,
        },
        {
          key: "cost_per_sqft",
          label: "$/sqft",
          sortable: true,
        },
      ],
      siteConditionsPerPage: 15,
      siteConditionsCurrentPage: 1,
      sortingArr: [
        "Drywall",
        "Glazing",
        "Doors",
        "Flooring",
        "Electrical (by Space)",
        "Electrical (Takeoff)",
        "Lighting",
        "Mechanical",
        "Kitchen & Millwork",
        "Wall Finishes",
        "Closeout & Other",
        "Suite/General Conditions",
        "General Requirements",
      ],
      sortingArr_v2: [
        "Drywall",
        "Glazing",
        "Doors",
        "Flooring",
        "Electrical",
        "Lighting",
        "Mechanical",
        "Kitchen & Millwork",
        "Wall Finishes",
        "Closeout & Other",
        "Suite/General Conditions",
        "General Requirements",
      ],
      quoteConstructionItems: [],
      selectedItem: {},
      cstrn_adjustment_item: [],
      // contigency percentage modal value
      showContingencyModal: false,
      contingency_fee_original_value: this.quote.contingency_fee_percentage,
      contingency_fee: this.quote.contingency_fee_percentage,
      // get codes from uniformat-codes-with-categories.json
      excluded_codes_contingency_fee: [
        "Z1070.30.01",
        "Z1070.30.02",
        "Z1070.30.03",
        "Z1070.40",
        "Z1070.50",
        "Z1070.60",
        "Z1070.80",
        "Z1050.20",
        "Z1050.25",
        "Z1050.30",
        "Z1020.10",
      ],
      excluded_codes_contingency_fee_v2:
          [
            "Z1070.30.03",
            "Z1070.60",
            "Z1020.10",
            "Z1020.10",
            "Z1070.30.02",
            "Z1070.30.02",
            "Z1070.60",
            "Z1050.35",
            "Z1070.20",
            "Z1010.10"
          ],
      // hide/show columns variables
      allSelected: false,
      fields: [
        {
          label: "COGS / Unit",
          key: "cogs_unit",
        },
        {
          label: "PRICE / Unit",
          key: "price_unit",
        },
        {
          label: "Quantity",
          key: "quantity",
        },
        {
          label: "Total COGS",
          key: "total_cost",
        },
        {
          label: "Total Price",
          key: "total_price",
        },
        {
          label: "COGS / SQFT",
          key: "cost_per_sqft",
        },
        {
          label: "Other Profit",
          key: "rebate",
        },
        // {
        //   label: "Actions",
        //   key: "actions",
        // },
      ],
      displayColumnsCheck: [
        "item",
        "cogs_unit",
        "price_unit",
        "quantity",
        "total_cost",
        "total_price",
        "cost_per_sqft",
        "rebate",
        "actions",
      ],
    };
  },
  components: {
    BDropdownForm, BDropdownDivider,
    BCard,
    BCardBody,
    BCol,
    BRow,
    BTable,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    vSelect,
    BPagination,
    BButton,
    BCardTitle,
    BSpinner,
    JsonExcel,
    BTableSimple,
    BTr,
    BTh,
    BThead,
    BTbody,
    BTd,
    UilArrowGrowth,
    UilArrowUp,
    UilArrowDown,
    UilEditAlt,
    ConstructionSwap,
    BFormInput,
    BFormGroup,
    BInputGroupPrepend,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    UilColumns,
  },
  directives: {
    "b-modal": VBModal,
  },
  computed: {
    drywall() {
      return this.quote.construction_spec_quote.filter((n) => n.category == "Drywall");
    },
    glazing() {
      return this.quote.construction_spec_quote.filter((n) => n.category == "Glazing");
    },
    doors() {
      return this.quote.construction_spec_quote.filter((n) => n.category == "Doors");
    },
    flooring() {
      return this.quote.construction_spec_quote.filter((n) => n.category == "Flooring");
    },
    electricalBySpace() {
      return this.quote.construction_spec_quote.filter(
        (n) => n.category == "Electrical (by Space)"
      );
    },
    electricalByTakeoff() {
      return this.quote.construction_spec_quote.filter(
        (n) => n.category == "Electrical (Takeoff)"
      );
    },
    lighting() {
      return this.quote.construction_spec_quote.filter((n) => n.category == "Lighting");
    },
    mechanical() {
      return this.quote.construction_spec_quote.filter((n) => n.category == "Mechanical");
    },
    kitchenMillwork() {
      return this.quote.construction_spec_quote.filter(
        (n) => n.category == "Kitchen & Millwork"
      );
    },
    wallFinishes() {
      return this.quote.construction_spec_quote.filter(
        (n) => n.category == "Wall Finishes"
      );
    },
    suiteGeneralConditions() {
      return this.quote.construction_spec_quote.filter(
        (n) => n.category == "Suite/General Conditions"
      );
    },
    constructionManagementFee() {
      return this.quote.construction_spec_quote.filter(
        (n) => n.category == "Construction Management Fee"
      );
    },

    constructionTotalCost() {
      var sum = 0;
      this.quote.construction_spec_quote.forEach((e) => {
        sum += parseFloat(e.total_cost);
      });

      this.quote.options
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_cost);
        });

      this.quote.site_conditions
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_cost);
        });

      return Number(sum).toFixed(2);
    },
    constructionTotalPrice() {
      var sum = 0;
      // add validation for cm fee and cstrn margin per item
      this.quote.construction_spec_quote.forEach((e) => {
        sum += parseFloat(e.total_price) + parseFloat(this.contingencyFeeCalc(e));
      });

      sum += this.addBcSubtotal;

      this.quote.site_conditions
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      sum += this.cmFeeCalc * 1;
      return Number(sum).toFixed(2);
    },
    // change logic to calculate cmfee calc
    // ticket https://www.notion.so/clearspace1/Contingency-CM-Fee-Formulas-302caff0f00b4c0d999e65f43f025d4b
    baseSpecItemsSubTotal() {
      var sum = 0;
      // add validation for cm fee and cstrn margin per item
      this.quote.construction_spec_quote.forEach((e) => {
        sum += parseFloat(e.total_price) + parseFloat(this.contingencyFeeCalc(e));
      });

      sum += this.addBcSubtotal;

      this.quote.site_conditions
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      return Number(sum).toFixed(2);
    },
    addBcSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "Construction" && c.is_base_spec)
        .reduce(
          (total, obj) =>
            Number(obj.total_price) + parseFloat(this.contingencyFeeCalc(obj)) + total,
          0
        );
      return result;
    },
    // cm fee calc
    cmFeeCalc() {
      let result = 0;
      if (this.quote.base_spec_version > 3.6) {
        result = this.quote.cm_fee_enabled
          ? Number(this.baseSpecItemsSubTotal) *
            (Number(this.quote.cm_fee_percentage) / 100)
          : 0;
      }
      return result;
    },
    baseSpecSelectedAll() {
      if (this.displayColumnsCheck.length <= 7) {
        this.allSelected = false;
      } else {
        this.allSelected = true;
      }
    },
  },
  props: [
    "quote",
    "constructionSpecs",
    "isBusy",
    "options",
    "cm_fee_enabled",
    "cm_fee_percentage",
  ],
  watch: {
    cm_fee_enabled: function (newVal, oldVal) {
      return newVal;
    },
    cm_fee_percentage: function (newVal, oldVal) {
      return newVal;
    },
  },
  mounted() {
    this.quoteConstructionItems = this.quote.construction_spec_quote.filter(
      (x) => x.name !== "Construction Management"
    );
  },
  methods: {
    constructtionSpecFiltered(name) {
      return this.quote.construction_spec_quote.filter((n) => n.category == name);
    },
    constructionAdjustments(constructionWorkId) {
      return this.options.filter((n) => n.construction_work_id == constructionWorkId);
    },
    constructionAdjustmentTotalUnitCost(constructionWorkId) {
      var sum = 0;
      this.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.unit_cost);
        });
      return parseFloat(sum);
    },
    constructionAdjustmentTotalQuantity(constructionWorkId) {
      var sum = 0;
      this.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.quantity);
        });
      return parseFloat(sum);
    },
    constructionAdjustmentRebateUnit(constructionWorkId) {
      var sum = 0;
      this.options
          .filter((n) => n.construction_work_id == constructionWorkId)
          .forEach((e) => {
            sum += parseFloat(e.rebate_total);
          });
      return parseFloat(sum);
    },
    constructionAdjustmentTotalCost(constructionWorkId) {
      var sum = 0;
      this.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.total_cost);
        });
      return parseFloat(sum);
    },
    constructionAdjustmentTotalPrice(constructionWorkId) {
      var sum = 0;
      this.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.total_price) + parseFloat(this.contingencyFeeCalc(e));
        });
      return parseFloat(sum);
    },
    constructionAdjustmentTotalUnitPrice(constructionWorkId) {
      var sum = 0;
      this.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.unit_price);
        });
      return parseFloat(sum);
    },
    constructionAdjustmentTotalCostPerSqft(constructionWorkId) {
      var sum = 0;
      this.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.cost_per_sqft);
        });
      return parseFloat(sum);
    },

    totalCost(name) {
      let sum = 0;
      let el = this.constructtionSpecFiltered(name);
      if (el.length > 0) {
        el.map((e) => {
          if (this.options.length > 0) {
            this.options.map((o) => {
              if (o.construction_work_id == e.construction_work_id) sum += o.total_cost;
            });
          }
          sum += e.total_cost;
        });
      }
      return sum;
    },
    totalPrice(name) {
      let sum = 0;
      let el = this.constructtionSpecFiltered(name);
      if (el.length > 0) {
        el.map((e) => {
          if (this.options.length > 0) {
            this.options.map((o) => {
              if (o.construction_work_id == e.construction_work_id) sum += o.total_price+ parseFloat(this.contingencyFeeCalc(o));
            });
          }
          sum += e.total_price + parseFloat(this.contingencyFeeCalc(e));
        });
      }
      return sum;
    },
    totalCostSqft(name) {
      let sum = 0;
      let el = this.constructtionSpecFiltered(name);
      if (el.length > 0) {
        el.map((e) => {
          if (this.options.length > 0) {
            this.options.map((o) => {
              if (o.construction_work_id == e.construction_work_id)
                sum += o.cost_per_sqft;
            });
          }
          sum += e.cost_per_sqft;
        });
      }
      return sum;
    },
    updateConstructionItem(item) {
      if (item.temp_id != undefined) {
        this.selectedItem = item;
      } else {
        item.temp_id = Date.now();
        this.selectedItem = item;
      }
      this.cstrn_adjustment_item = this.constructionAdjustments(
        item.construction_work_id
      );

      this.$bvModal.show("construction-options-modal");
    },
    childOptions(el) {
      this.$emit("base_addition", el);
      this.selectedItem = [];
    },
    // spec swaps emit for edit view
    cstrnSwapSpec(el) {
      this.$emit("spec_swap", el);
    },
    deleteCstrnItem(item) {
      this.cstrn_adjustment_item = [];
      this.$emit("delete_cstrn_spec", item);
    },
    updateContingencyFee(bvModalEvent) {
      bvModalEvent.preventDefault();
      if (!this.contingency_fee == "") {
        this.$emit("update_contingency_fee_percentage", this.contingency_fee);
        this.showContingencyModal = false;
        this.contingency_fee_original_value = this.contingency_fee;
      }
    },
    resetContingencyFee() {
      this.contingency_fee = this.contingency_fee_original_value;
    },
    // calculate and filter items totals for contingency fee
    contingencyFeeCalc(construction_spec) {
      let result =
        this.quote.base_spec_version > 3.6
          ? Number(
              Number(Number(construction_spec.total_cost)) *
                (Number(this.contingency_fee) / 100)
            )
          : 0;
      if (
        this.excluded_codes_contingency_fee.filter(
          (e) => e == construction_spec.uniformat
        ).length > 0 && this.quote.base_spec_version > 3.6 && this.quote.base_spec_version< 4.2
      ) {
        result = 0;
      }
      if (
          this.excluded_codes_contingency_fee_v2.filter(
              (e) => e == construction_spec.uniformat
          ).length > 0 && this.quote.base_spec_version >= 4.2
      ) {
        result = 0;
      }
      // // create custom adjustment for elements that qty is adjusted to 0
      // if (
      //   construction_spec.qty +
      //     this.constructionAdjustmentTotalQuantity(
      //       construction_spec.construction_work_id
      //     ) ==
      //   0
      // ) {
      //   result = 0;
      // }
      // let cstrn_iten_filter = this.quote.construction_spec_quote.find((c)=>c.construction_work_id==construction_spec.construction_work_id);
      // if (cstrn_iten_filter&&
      //   cstrn_iten_filter.qty +
      //     this.constructionAdjustmentTotalQuantity(
      //       construction_spec.construction_work_id
      //     ) ==
      //   0
      // ) {
      //   result = 0;
      // }
      return result;
    },
    hierarchy_grouping_total_price(level_2_name){
    let group = this.quote.construction_hierarchy.find((h)=>h.level_2_name===level_2_name);
      let sum = 0;
      if (group.items.length > 0) {
        group.items.map((e) => {
          if (this.options.length > 0) {
            this.options.map((o) => {
              if (o.construction_work_id == e.construction_work_id) sum += o.total_price+ parseFloat(this.contingencyFeeCalc(o));
            });
          }
          sum += e.total_price + parseFloat(this.contingencyFeeCalc(e));
        });
      }
      return sum;
    },
    hierarchy_grouping_total_cogs(level_2_name){
      let sum =0
      let group = this.quote.construction_hierarchy.find((h)=>h.level_2_name===level_2_name);
      if (group.items.length > 0) {
        group.items.map((e) => {
          if (this.options.length > 0) {
            this.options.map((o) => {
              if (o.construction_work_id == e.construction_work_id) sum += o.total_cost;
            });
          }
          sum += e.total_cost;
        });
      }
      return sum;
    },
    toggleAllDrop(checked) {
      if (checked) {
        this.displayColumnsCheck = ["name"];
        if (this.fields.length > 0) {
          this.fields.map((f) => {
            this.displayColumnsCheck.push(f.key);
          });
        }
      } else {
        this.displayColumnsCheck = ["name"];
      }
    },
    resetCols() {
        this.displayColumnsCheck = [
          "item",
          "cogs_unit",
          "price_unit",
          "quantity",
          "total_cost",
          "total_price",
          "cost_per_sqft",
          "rebate",
          "actions",
        ];
    },
    showColumnsFiltered(col) {
      let isSelected = false
      if (this.displayColumnsCheck.length > 0) {
        const res = this.displayColumnsCheck.filter(c => c == col)
        if (res.length != 0) {
          isSelected = true
        }
      }
      return isSelected
    },
  },
};
</script>

<style scoped>
.label-exclusions {
  margin: auto;
}

.increase {
  color: rgba(59, 171, 255, 1);
}

.decrease {
  color: #ea5455;
}

.first-row-border {
  border-top: 1px solid #ebe9f1;
  border-bottom: 1px solid #ebe9f1;
}

.following-row-border {
  border-bottom: 1px solid #ebe9f1;
}

.cell-styling {
  height: 33px;
}

.cell-styling-double {
  height: 65px;
}
.border-group-details {
  border-bottom: 1px solid rgba(31, 41, 47, 0.05);
}
.muted-number {
  color: #b9b9c3;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.muted-base-spec {
  color: #b9b9c3;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
}
</style>
